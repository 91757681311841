<template>
  <CommonDetailsWrapper
    ref="wrapper"
    wrapper-class="schedule"
    @close="handleClose"
    @go-back="handleClose"
  >
    <template #customHeader>
      <button
        v-if="step !== 'INVITE'"
        class="ScheduleMeeting__close"
        @click="handleClose"
      >
        <IconClose />
      </button>
      <button
        v-if="step === 'INVITE'"
        class="ScheduleMeeting__close"
        @click="prevStep"
      >
        <o-icon custom-size="mdi-24px" icon="chevron-left" />
      </button>
      Schedule a meeting
      <span
        v-if="step !== 'SUCCESS' && step !== 'ERROR'"
        class="ScheduleMeeting__step-indicator"
        >{{ step === "INITIAL" ? "1" : "2" }}/2</span
      >
    </template>
    <template #content>
      <div class="ScheduleMeeting__wrapper">
        <div v-show="step === 'INITIAL'" class="ScheduleMeeting__step-1">
          <FormDropdown
            ref="room"
            name="room"
            :options="roomOptions"
            label="Choose room"
          />
          <FormInput ref="name" name="name" label="Enter meeting's name" />
          <FormDatePicker ref="startDay" name="startDay" label="Date" />
          <FormDropdownTimepicker
            ref="startTime"
            label="Start Time"
            name="startTime"
            placeholder="Start Time"
            :min-hour="minHour"
          />
          <FormDropdown
            ref="duration"
            label="Duration"
            name="duration"
            :options="durationOptions"
            placeholder="Duration"
            :disabled="!chosenRoom"
          />
          <FormDropdown
            ref="repeatability"
            name="repeatability"
            label="Repeat event"
            :options="repeatOptions"
          />
        </div>
        <div v-show="step === 'INVITE'" class="ScheduleMeeting__step-2">
          <div class="ScheduleMeeting__invite-header">
            <h3 class="ScheduleMeeting__invite-header-text">
              Invite participants
            </h3>
            <p class="ScheduleMeeting__invite-header-description">Optional</p>
          </div>
          <FormSearch ref="search" label="Search" placeholder="Search" />
          <ul
            v-if="contactsState.data.length > 0"
            class="ScheduleMeeting__participants-list"
          >
            <li
              v-for="(contact, index) in contactsState.data"
              :key="index"
              class="ScheduleMeeting__participants-list-element"
            >
              <DashboardListParticipant v-bind="contact" />
              <FormCheckbox
                ref="checkboxes"
                :key="getCheckedValue(contact.id)"
                :value-when-true="true"
                :initial-value="getCheckedValue(contact.id)"
                @update:model-value="
                  (value) => handleUpdateModelValue(value, contact.id)
                "
              />
            </li>
          </ul>
        </div>
        <div v-show="step === 'SUCCESS'" class="ScheduleMeeting__step-3">
          <div class="ScheduleMeeting__illustration-wrapper">
            <DashboardIllustrationScheduleMeeting />
            <h3 class="ScheduleMeeting__illustration-heading">
              Meeting scheduled!
            </h3>
            <p class="ScheduleMeeting__illustration-description">
              Invitations have been sent to participants. Details & share link
              are available below.
            </p>
          </div>
          <div class="ScheduleMeeting__created-meeting-wrapper"></div>
          <div class="ScheduleMeeting__share-wrapper">
            <FormLinkShare :label="'Share room link'" :url="roomUrl" />
          </div>
        </div>
        <div v-show="step === 'ERROR'" class="ScheduleMeeting__step-4">
          <div class="ScheduleMeeting__illustration-wrapper">
            <DashboardIllustrationError />
            <h3 class="ScheduleMeeting__illustration-heading">
              Something went wrong
            </h3>
            <p class="ScheduleMeeting__illustration-description">
              We couldn't schedule your meeting. Please try again.
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div v-show="step === 'INITIAL'" class="ScheduleMeeting__button-wrapper">
        <button class="ScheduleMeeting__button" @click="nextStep">
          <o-icon icon="account-plus" custom-size="mdi-18px" />
          Invite participants
        </button>
      </div>
      <div v-show="step === 'INVITE'" class="ScheduleMeeting__button-wrapper">
        <button class="ScheduleMeeting__button" @click="handleCreateMeeting">
          {{
            checkedNumber > 0
              ? "Invite and create meeting"
              : "Skip and create meeting"
          }}
        </button>
      </div>
      <div v-show="step === 'SUCCESS'" class="ScheduleMeeting__button-wrapper">
        <button class="ScheduleMeeting__button" @click="handleClose">
          Return to home screen
        </button>
      </div>
      <div v-show="step === 'ERROR'" class="ScheduleMeeting__button-wrapper">
        <button class="ScheduleMeeting__button" @click="prevStep">
          Go back
        </button>
      </div>
    </template>
  </CommonDetailsWrapper>
</template>
<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { ref, computed } from "vue";
import { DateTime, Duration } from "luxon";
import { watchThrottled } from "@vueuse/core";
import * as yup from "yup";
import {
  ContactsResponse,
  Profile,
  MeetingCreateResponse,
  Room,
  RoomsResponse,
} from "@/types";
import { useDashboardStore } from "~/store/dashboard";
import { mapDataToOptions } from "~/components/Form/Dropdown/helpers";
import { useAnalytics } from "~/composables/useAnalytics";

type ContactsState = {
  status: "LOADED" | "LOADING" | "INITIAL";
  nextToken: string;
  data: Profile[];
};

const runtimeConfig = useRuntimeConfig();
const dashboardStore = useDashboardStore();
const analytics = useAnalytics();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const handleClose = () => {
  emit("close");
};

const wrapper = ref<{ containerRef: HTMLElement | null }>({
  containerRef: null,
});

type Input = { value: string } | null;
type DateInput = { value: Date } | null;
const room = ref<Input>(null);
const name = ref<Input>(null);
const startDay = ref<DateInput>(null);
const startTime = ref<Input>(null);
const duration = ref<Input>(null);
const repeatability = ref<Input>(null);

const step = ref<"INITIAL" | "INVITE" | "SUCCESS" | "ERROR">("INITIAL");

const search = ref<Input>(null);
const checked = ref(new Map());
const checkboxes = ref([]);

const roomUrl = ref("");

const contactsState = ref<ContactsState>({
  status: "INITIAL",
  nextToken: "",
  data: [],
});

const rooms = await useAuthRequest<RoomsResponse>(
  `${runtimeConfig.public.backendApiUrl}/rooms`,
);

const roomOptions = computed(() => {
  if (rooms.data.value) {
    const activeRooms = rooms.data.value.rooms.filter(
      (item) => item.status === "ready" || item.status === "ready_to_renew",
    );
    return mapDataToOptions<Room>({
      data: activeRooms,
      value: "id",
      label: "name",
    });
  }
  return [];
});

const chosenRoom = computed(() => {
  if (!room.value?.value) {
    return;
  }
  return rooms.data.value.rooms.find((item) => item.id === room.value?.value);
});

const contactsUrl = computed(() => {
  const url = new URL(
    `${runtimeConfig.public.backendApiUrl}/users/kingschat_contacts`,
  );
  url.searchParams.set("next_token", contactsState.value.nextToken);
  if (search.value?.value) {
    url.searchParams.set("q", `${search.value.value}`);
  }
  return url;
});

const fetchContacts = async () => {
  if (contactsState.value.status === "LOADING") {
    return;
  }
  contactsState.value.status = "LOADING";
  return await useAuthRequest<ContactsResponse>(contactsUrl.value.href)
    .then((res) => {
      if (res.data.value.profiles.length > 0) {
        contactsState.value.data.push(...res.data.value.profiles);
      }
      contactsState.value.nextToken = res.data.value.next_token;
      contactsState.value.status = "LOADED";
    })
    .catch((err) => {
      Sentry.captureException(err);
      contactsState.value.status = "LOADED";
    });
};

const handleUpdateModelValue = computed(() => {
  return (value: boolean, id: string) => {
    checked.value.set(id, value);
  };
});

const getCheckedValue = computed(() => {
  return (id: string) => {
    return checked.value.get(id) || false;
  };
});

const minHour = computed(() => {
  if (startDay.value?.value) {
    const startDate = DateTime.fromJSDate(startDay.value.value).toLocal();
    const today = DateTime.now().toLocal();
    if (startDate.startOf("day").hasSame(today.startOf("day"), "day")) {
      return today.toISO();
    }
  }
  const min = new Date();
  min.setHours(0);
  min.setMinutes(0);
  return `${DateTime.fromJSDate(min).toISO()}`;
});

const durationMinutes = computed(() => {
  const result = [10, 20, 30, 40, 50].map((item) => {
    const durationValue = item * 60;
    const durationLabel = Duration.fromObject({ seconds: durationValue })
      .shiftTo("minutes")
      .toHuman();
    return {
      value: `${durationValue}`,
      label: durationLabel,
      disabled: false,
    };
  });
  return result;
});

const durationHours = computed(() => {
  const result = [...Array(47).keys()]
    .map((i) => i / 2 + 1)
    .map((item) => {
      const durationValue = item * 3600;
      const duration = Duration.fromObject({ seconds: durationValue }).shiftTo(
        "hours",
        "minutes",
      );

      return {
        value: `${durationValue}`,
        label:
          duration.minutes > 0
            ? duration.toHuman()
            : duration.shiftTo("hours").toHuman(),
        disabled: false,
      };
    });
  return result;
});

const durationOptions = computed(() => {
  if (!chosenRoom.value) {
    return [];
  }
  const res = [...durationMinutes.value, ...durationHours.value].filter(
    (item) => {
      if (!chosenRoom.value) {
        return false;
      }
      return parseInt(item.value) <= chosenRoom.value?.max_duration_in_sec;
    },
  );
  return res;
});

const repeatOptions = [
  { value: "none", label: "Dont repeat", disabled: false },
  { value: "daily", label: "Daily", disabled: false },
  { value: "weekly", label: "Weekly", disabled: false },
];

const nextStep = () => {
  validateForm().then(() => {
    if (metaForm.value.valid) {
      step.value = "INVITE";
    }
  });
};
const prevStep = () => {
  step.value = "INITIAL";
};

const checkedNumber = computed(() => {
  return [...checked.value].filter(([_key, value]) => value).length;
});

const validateStartDay = (value: Date) => {
  const now = DateTime.now();
  const startDay = DateTime.fromJSDate(value);
  if (startDay.startOf("day").hasSame(now, "day") || startDay >= now) {
    return true;
  }
  return false;
};

const validateStartTime = (value: string) => {
  const now = DateTime.now().toLocal().toJSDate();
  const dateValue = DateTime.fromISO(value).toJSDate();

  if (!startDay.value?.value) {
    return true;
  }
  const startDate = DateTime.fromJSDate(startDay.value.value).toLocal();
  if (
    startDate
      .startOf("day")
      .hasSame(DateTime.now().toLocal().startOf("day"), "day")
  ) {
    if (dateValue > now) {
      return true;
    }
    return false;
  }
  return true;
};

const { validate: validateForm, meta: metaForm } = useForm({
  validationSchema: yup.object({
    name: yup
      .string()
      .required("Please enter a value")
      .min(3, "Please enter at least 3 characters.")
      .max(60, "Please enter no more than 60 characters.")
      .trim(),
    startDay: yup
      .date()
      .required("Please enter a value")
      .test(
        "startDay",
        "Meetings cannot be scheduled in the past. Please choose a future start day.",
        (value) => {
          return validateStartDay(value);
        },
      ),
    startTime: yup
      .string()
      .required("Please enter a value")
      .test(
        "startTime",
        "Meetings cannot be scheduled in the past. Please choose a future start time.",
        (value) => {
          return validateStartTime(value);
        },
      ),
    room: yup.string().required("Please enter a value"),
    repeatability: yup.string().required("Please enter a value"),
    duration: yup.string().required("Please enter a value"),
  }),
});

const handleCreateMeeting = async () => {
  const invited = [...checked.value]
    .filter(Boolean)
    .map(([key, _value]) => key);
  const time =
    startTime.value && startTime.value.value
      ? DateTime.fromISO(startTime.value.value).toLocal().toUTC().toISOTime()
      : "";
  const day =
    startDay.value && startDay.value.value && startTime.value
      ? DateTime.fromJSDate(startDay.value.value)
          .toLocal()
          .plus({
            hours: DateTime.fromISO(startTime.value.value).toLocal().hour,
            minutes: DateTime.fromISO(startTime.value.value).toLocal().minute,
          })
          .toUTC()
          .toFormat("yyyy-MM-dd")
      : "";
  const payload = {
    name: name.value?.value,
    room_id: room.value?.value,
    repeatability: repeatability.value?.value,
    start_day: day,
    start_time: time,
    duration_in_sec: duration.value ? +duration.value.value : 3600,
    invited_users_ids: invited,
  };
  await useAuthRequest<MeetingCreateResponse>(
    `${runtimeConfig.public.backendApiUrl}/meetings`,
    {
      method: "POST",
      body: payload,
    },
  ).then(async (res) => {
    if (!res.pending.value && res.error.value) {
      step.value = "ERROR";
    }
    if (!res.pending.value && !res.error.value) {
      roomUrl.value = `${window.origin}/room/${res.data.value.meeting.room_private_url}`;
      step.value = "SUCCESS";
      if (chosenRoom.value) {
        analytics.eventSchedule({
          duration: payload.duration_in_sec,
          repeatability: payload.repeatability || "",
          invitedCount: payload.invited_users_ids.length,
          maxDuration: chosenRoom.value.max_duration_in_sec,
        });
      }
      await dashboardStore.refreshCalendarEvents();
    }
  });
};

watchThrottled(
  () => search.value?.value,
  async (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }
    contactsState.value.data = [];
    await fetchContacts();
  },
  {
    throttle: 1500,
  },
);

useInfiniteScroll(
  () => wrapper.value?.containerRef || null,
  async () => {
    if (step.value === "INVITE") {
      await fetchContacts();
    }
  },
  {
    distance: 100,
    canLoadMore: () => {
      if (
        step.value === "INVITE" &&
        contactsState.value.status === "LOADED" &&
        contactsState.value.nextToken === ""
      ) {
        return false;
      }
      return true;
    },
  },
);

onMounted(() => {
  analytics.scheduleInitiation();
});
</script>
<style scoped lang="scss">
.ScheduleMeeting {
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: calc(100% - 16px);
    min-height: calc(100% - 16px);
  }
  &__close {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__step-indicator {
    @include label-l;
    font-variant-numeric: tabular-nums;
    position: absolute;
    padding: 8px 12px;
    color: $color-element-light-strong;
    background: $color-element-dark-disabled;
    border-radius: 12px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &__step-1 {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &__step-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__invite-header {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    margin-bottom: 16px;
    align-items: baseline;
  }
  &__invite-header-text {
    @include header-s;
    color: $color-element-dark-strong;
  }
  &__invite-header-description {
    @include body-l;
    color: $color-element-dark-medium;
  }
  &__illustration-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__illustration-heading {
    @include header-m;
    color: $color-element-dark-strong;
    margin-top: 4px;
    margin-bottom: 16px;
  }
  &__illustration-description {
    @include body-l;
    color: $color-element-dark-medium;
    text-align: center;
  }
  &__share-wrapper {
    margin-top: auto;
  }
  &__button-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-top: 1px solid $color-neutral-300;
  }
  &__button {
    @include button-primary;
    display: row;
    column-gap: 8px;
  }
  &__participants-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: 0;
    padding: 0;
    margin-top: 24px;
  }
  &__participants-list-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
