<template>
  <CommonBottomDrawer @close="handleClose">
    <template #header> Renew your room </template>
    <template #content>
      <div class="RoomOffer__wrapper">
        <div class="RoomOffer__title-wrapper">
          <component :is="iconComponent" v-bind="{ expired: false }" />
          <h3 class="RoomOffer__title-text">{{ room.data.value?.name }}</h3>
          <h4
            class="RoomOffer__label"
            :class="{ expired: room.data.value?.status === 'expired' }"
          >
            {{ expireWarning }}
          </h4>
        </div>
        <ul class="RoomOffer__features">
          <li
            v-for="(feature, index) in featureList"
            :key="index"
            class="RoomOffer__feature"
          >
            <o-icon :icon="feature.icon" custom-size="mdi-20px" />
            {{ feature.text }}
          </li>
        </ul>
        <DashboardRoomOfferPriceContainer
          v-if="offerWithChosenCurrency"
          :offer="offerWithChosenCurrency"
          @pay-now="handlePayNowClick"
        />
      </div>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
import { DateTime } from "luxon";
import { useCurrencyStore } from "~/store/currency";
import { useDashboardStore } from "~/store/dashboard";
import { OfferType, RoomDetailsResponse } from "~/types";
const runtimeConfig = useRuntimeConfig();

type Props = {
  roomId: string;
};

const emit = defineEmits<{
  (e: "close"): void;
}>();

const props = defineProps<Props>();
const currencyStore = useCurrencyStore();
const dashboardStore = useDashboardStore();

const annuallyOffer = ref(false);

const room = await useAuthRequest<RoomDetailsResponse>(
  `${runtimeConfig.public.backendApiUrl}/rooms/${props.roomId}`,
);
const offerType = await useAuthRequest<OfferType>(
  `${runtimeConfig.public.backendApiUrl}/rooms/${props.roomId}/offers
`,
);

const roomExpirationDateTime = computed(() => {
  return DateTime.fromISO(room.data.value.expiration_datetime, {
    zone: "utc",
  }).toLocal();
});

const expireWarning = computed(() => {
  if (!room.data.value) {
    return "";
  }
  if (room.data.value.status === "expired") {
    return "Expired";
  }
  if (room.data.value.status === "ready_to_renew") {
    return `Expires ${roomExpirationDateTime.value.toRelativeCalendar()}`;
  }
  return "";
});

const offerWithChosenCurrency = computed(() => {
  if (!offerType.data.value) {
    return;
  }
  return offerType.data.value.offers
    .filter((offer) => {
      if (!annuallyOffer.value) {
        return ["monthly", "monthly_limited"].includes(offer.subscription_type);
      }
      return offer.subscription_type === "annual";
    })
    .find(
      (offer) => offer.currency === currencyStore.defaultCurrency.currency.code,
    );
});

const featureList = computed(() => {
  switch (offerType.data.value?.offer_code) {
    case "AUDIO_ROOM":
      return [
        {
          icon: "account-multiple",
          text: `Up to ${offerType.data.value.max_participants} participants`,
        },
        {
          icon: "clock-time-five-outline",
          text: `Meetings up to ${offerType.data.value.max_duration_in_sec / 3600} hours`,
        },
        {
          icon: "forum",
          text: "Dynamic in-meeting chat & reactions",
        },
        {
          icon: "cog",
          text: "Manage participants easily",
        },
      ] as const;
    case "BIG_AUDIO_ROOM":
      return [
        {
          icon: "account-multiple",
          text: `Up to ${offerType.data.value.max_participants} participants`,
        },
        {
          icon: "forum",
          text: "Dynamic in-meeting chat & reactions",
        },
        {
          icon: "cog",
          text: "Manage participants easily",
        },
      ] as const;
    case "VIDEO_ROOM":
      return [
        {
          icon: "account-multiple",
          text: `Up to ${offerType.data.value.max_participants} participants`,
        },
        {
          icon: "clock-time-five-outline",
          text: `Meetings up to ${offerType.data.value.max_duration_in_sec / 3600} hours`,
        },
        {
          icon: "forum",
          text: "Dynamic in-meeting chat & reactions",
        },
        {
          icon: "cog",
          text: "Manage participants easily",
        },
      ] as const;
    case "BIG_VIDEO_ROOM":
      return [
        {
          icon: "account-multiple",
          text: `Up to ${offerType.data.value.max_participants} participants`,
        },
        {
          icon: "forum",
          text: "Dynamic in-meeting chat & reactions",
        },
        {
          icon: "cog",
          text: "Manage participants easily",
        },
      ] as const;
    default:
      return [];
  }
});

const iconAudio = resolveComponent("DashboardIconAudio");
const iconVideo = resolveComponent("DashboardIconVideo");
const iconStreamed = resolveComponent("DashboardIconStreamed");

const iconComponent = computed(() => {
  if (!offerType.data.value) {
    return;
  }
  switch (offerType.data.value.room_type) {
    case "audio":
      return iconAudio;
    case "video":
      return iconVideo;
    case "streamed":
      return iconStreamed;
  }
});

const handleClose = () => {
  emit("close");
};

const handlePayNowClick = () => {
  if (!offerWithChosenCurrency.value) {
    return;
  }
  dashboardStore.handleRenewRoom(
    props.roomId,
    offerWithChosenCurrency.value.id,
    offerWithChosenCurrency.value.currency,
  );
};
</script>
<style scoped lang="scss">
.RoomOffer {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__title-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  &__title-text {
    @include header-m;
    color: $color-element-dark-strong;
  }
  &__label {
    @include label-m;
    padding: 8px 12px;
    color: $color-basic-danger-500;
    background: $color-basic-danger-100;
    border-radius: 12px;
    &.expired {
      color: $color-element-dark-medium;
      background: $color-neutral-100;
    }
  }
  &__features {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 24px;
    border-top: 2px solid $color-neutral-100;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  &__feature {
    @include body-l;
    padding: 0;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    color: $color-element-dark-medium;
  }
}
</style>
