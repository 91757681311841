<template>
  <div
    class="PriceContainer__options-wrapper"
    :class="{ discount: offer.percentage_discount }"
  >
    <div class="PriceContainer__subscription-type-wrapper">
      <p class="PriceContainer__subscription-type-label">Monthly</p>
      <FormSwitch :initial-value="false" :disabled="true" />
      <p class="PriceContainer__subscription-type-label">
        Annualy
        <span
          v-if="offer.percentage_discount"
          class="PriceContainer__percentage-discount"
        >
          -{{ offer.percentage_discount }}%
        </span>
      </p>
    </div>
    <div class="PriceContainer__price-container">
      <div class="PriceContainer__price-wrapper">
        <h4 class="PriceContainer__price-text">
          {{ price.current }}
        </h4>
        <h5
          v-if="offer.percentage_discount"
          class="PriceContainer__price-before-discount"
        >
          {{ price.beforeDiscount }}
        </h5>
      </div>
      <button
        class="PriceContainer__button-currency"
        @click="handleChooseCurrencyClick"
      >
        <DashboardIconCurrencyExchange />
        Currency
      </button>
    </div>
    <button class="PriceContainer__button-pay" @click="handlePayNowClick">
      <DashboardIconKingspay /> Pay now with KingsPay
    </button>
  </div>
</template>
<script setup lang="ts">
import { useDashboardStore } from "~/store/dashboard";
import { CURRENCY_SETTINGS, CurrencyCode, Offer } from "~/types";

type Props = {
  offer: Offer;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "pay-now", offerId: string, currency: CurrencyCode): void;
}>();

const dashboardStore = useDashboardStore();

const price = computed(() => {
  return {
    current: `${CURRENCY_SETTINGS[props.offer.currency].symbol}${(
      props.offer.price / 100
    ).toFixed(2)}`,
    beforeDiscount: `${CURRENCY_SETTINGS[props.offer.currency].symbol}${(
      props.offer.price_before_discount / 100
    ).toFixed(2)}`,
  };
});

const handleChooseCurrencyClick = () => {
  dashboardStore.handleChooseCurrencyOpen();
};

const handlePayNowClick = () => {
  emit("pay-now", props.offer.id, props.offer.currency);
};
</script>
<style scoped lang="scss">
.PriceContainer {
  &__options-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 12px;
    background: $brand-primary-050;
    &.discount {
      background: $color-basic-success-100;
    }
  }
  &__subscription-type-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: auto;
    column-gap: 12px;
    padding-bottom: 12px;
  }
  &__subscription-type-label {
    @include body-l;
    color: $color-element-dark-medium;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__percentage-discount {
    @include label-m;
    color: $color-basic-success-500;
    padding: 4px 6px;
    border-radius: 8px;
    border: 1px solid $color-basic-success-500;
    margin-left: 6px;
  }
  &__price-container {
    border-top: 2px solid $color-element-dark-weak;
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__price-wrapper {
    display: flex;
    flex-direction: row;
  }
  &__price-text {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1.65px;
    color: $color-primary;
    margin: 0;
  }
  &__price-before-discount {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.43px;
    text-decoration: line-through;
    color: $color-basic-danger-500;
    margin: 0;
    padding: 9px 0;
  }
  &__button-currency {
    @include label-l;
    color: $color-element-dark-strong;
    display: inline-flex;
    flex-direction: row;
    padding: 7px 11px;
    border-radius: 999px;
    column-gap: 6px;
    border: 1px solid $color-neutral-700;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    background: none;
    cursor: pointer;
  }
  &__button-pay {
    @include button-primary;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
  }
}
</style>
