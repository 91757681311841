<template>
  <CommonBottomDrawer @close="handleClose">
    <template #header>Choose currency</template>
    <template #content>
      <div class="ChooseCurrency__wrapper">
        <button
          v-for="currency in currencyStore.currencies"
          :key="currency.code"
          class="ChooseCurrency__button"
          @click="handleChangeCurrency(currency)"
        >
          <o-icon
            :icon="getCurrencyIconName(currency.code)"
            class="ChooseCurrency__currency-icon"
          />
          {{ currency.name }}
          <o-icon
            v-if="currencyStore.defaultCurrency.currency.code === currency.code"
            class="ChooseCurrency__checkmark"
            icon="check"
          />
        </button>
      </div>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
import { useCurrencyStore } from "~/store/currency";
import { CurrencySettings, CurrencyCode } from "~/types";

const emit = defineEmits<{
  (e: "close"): void;
}>();

const currencyStore = useCurrencyStore();

const handleClose = () => {
  emit("close");
};

const handleChangeCurrency = (currency: CurrencySettings) => {
  currencyStore.setDefaultCurrency(currency);
  handleClose();
};

const getCurrencyIconName = (currencyCode: CurrencyCode) => {
  switch (currencyCode) {
    case "GBP":
      return "currency-gbp";
    case "USD":
      return "currency-usd";
    case "NGN":
      return "currency-ngn";
  }
};
</script>
<style scoped lang="scss">
.ChooseCurrency {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    width: 100%;
    margin-top: 16px;
  }
  &__button {
    @include body-l;
    color: $color-element-dark-strong;
    background: none;
    border: none;
    padding: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  &__currency-icon {
    font-size: 24px;
    color: $color-element-dark-disabled;
  }
  &__checkmark {
    margin-left: auto;
    color: $color-basic-success-500;
  }
}
</style>
