<template>
  <CommonDetailsWrapper
    ref="wrapper"
    wrapper-class="schedule"
    @close="handleCloseOnClickOutside"
  >
    <template #customHeader>
      <button class="ScheduleMeeting__close" @click="handleCloseOnClickOutside">
        <IconClose />
      </button>
      Edit meeting
    </template>
    <template #content>
      <div v-if="room.data.value" class="ScheduleMeeting__wrapper">
        <div v-show="step === 'INITIAL'" class="ScheduleMeeting__step-1">
          <FormInput
            ref="nameInput"
            name="name"
            :initial-value="name"
            label="Enter meeting's name"
          />
          <FormDatePicker
            ref="startDay"
            :initial-value="initialStartDay"
            name="startDay"
            label="Date"
          />
          <FormDropdownTimepicker
            ref="startTime"
            label="Start Time"
            name="startTime"
            placeholder="Start Time"
            :initial-value="initialStartTime"
            :min-hour="minHour"
          />
          <FormDropdown
            ref="duration"
            label="Duration"
            name="duration"
            :options="durationOptions"
            :initial-value="initialDuration"
            placeholder="Duration"
          />
          <FormDropdown
            ref="repeatability"
            name="repeatability"
            label="Repeat event"
            :options="repeatOptions"
            :initial-value="props.repeatability"
          />
        </div>
        <div v-show="step === 'SUCCESS'" class="ScheduleMeeting__step-3">
          <div class="ScheduleMeeting__illustration-wrapper">
            <DashboardIllustrationScheduleMeeting />
            <h3 class="ScheduleMeeting__illustration-heading">
              Meeting updated!
            </h3>
          </div>
        </div>
        <div v-show="step === 'ERROR'" class="ScheduleMeeting__step-4">
          <div class="ScheduleMeeting__illustration-wrapper">
            <DashboardIllustrationError />
            <h3 class="ScheduleMeeting__illustration-heading">
              Something went wrong
            </h3>
            <p class="ScheduleMeeting__illustration-description">
              We couldn't update your meeting. Please try again.
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div v-show="step === 'INITIAL'" class="ScheduleMeeting__button-wrapper">
        <button class="ScheduleMeeting__button" @click="handleCreateMeeting">
          <o-icon icon="account-plus" custom-size="mdi-18px" />
          Save changes
        </button>
      </div>
      <div v-show="step === 'SUCCESS'" class="ScheduleMeeting__button-wrapper">
        <button class="ScheduleMeeting__button" @click="handleCloseAll">
          Return to home screen
        </button>
      </div>
      <div v-show="step === 'ERROR'" class="ScheduleMeeting__button-wrapper">
        <button class="ScheduleMeeting__button" @click="prevStep">
          Go back
        </button>
      </div>
    </template>
  </CommonDetailsWrapper>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { DateTime, Duration } from "luxon";
import * as yup from "yup";
import { MeetingCreateResponse, RoomDetailsResponse } from "@/types";
import { useDashboardStore } from "~/store/dashboard";

type Props = {
  meetingId: string;
  name: string;
  startDatetime: string;
  endDatetime: string;
  roomId: string;
  repeatability: string;
};

const runtimeConfig = useRuntimeConfig();
const dashboardStore = useDashboardStore();

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "calendar-event-details-close"): void;
}>();

const handleCloseOnClickOutside = () => {
  if (step.value !== "SUCCESS") {
    return handleClose();
  }
  return handleCloseAll();
};

const handleClose = () => {
  emit("close");
};

const handleCloseAll = () => {
  emit("close");
  emit("calendar-event-details-close");
};

const room = await useAuthRequest<RoomDetailsResponse>(
  `${runtimeConfig.public.backendApiUrl}/rooms/${props.roomId}`,
);

const initialStartDay = computed(() => {
  return DateTime.fromISO(props.startDatetime, { zone: "utc" })
    .toLocal()
    .startOf("day")
    .toJSDate();
});

const initialStartTime = computed(() => {
  return DateTime.now()
    .startOf("day")
    .plus({
      hours: DateTime.fromISO(props.startDatetime, { zone: "utc" }).toLocal()
        .hour,
      minutes: DateTime.fromISO(props.startDatetime, { zone: "utc" }).toLocal()
        .minute,
    })
    .toISO();
});

const wrapper = ref<{ containerRef: HTMLElement | null }>({
  containerRef: null,
});

type Input = { value: string } | null;
type DateInput = { value: Date } | null;
const nameInput = ref<Input>(null);
const startDay = ref<DateInput>(null);
const startTime = ref<Input>(null);
const duration = ref<Input>(null);
const repeatability = ref<Input>(null);

const step = ref<"INITIAL" | "SUCCESS" | "ERROR">("INITIAL");

const minHour = computed(() => {
  if (startDay.value?.value) {
    const startDate = DateTime.fromJSDate(startDay.value.value).toLocal();
    const today = DateTime.now().toLocal();
    if (startDate.startOf("day").hasSame(today.startOf("day"), "day")) {
      return today.toISO();
    }
  }
  const min = new Date();
  min.setHours(0);
  min.setMinutes(0);
  return `${DateTime.fromJSDate(min).toISO()}`;
});

const durationMinutes = computed(() => {
  const result = [10, 20, 30, 40, 50].map((item) => {
    const durationValue = item * 60;
    const durationLabel = Duration.fromObject({ seconds: durationValue })
      .shiftTo("minutes")
      .toHuman();
    return {
      value: `${durationValue}`,
      label: durationLabel,
      disabled: false,
    };
  });
  return result;
});

const durationHours = computed(() => {
  const result = [...Array(47).keys()]
    .map((i) => i / 2 + 1)
    .map((item) => {
      const durationValue = item * 3600;
      const duration = Duration.fromObject({ seconds: durationValue }).shiftTo(
        "hours",
        "minutes",
      );

      return {
        value: `${durationValue}`,
        label:
          duration.minutes > 0
            ? duration.toHuman()
            : duration.shiftTo("hours").toHuman(),
        disabled: false,
      };
    });
  return result;
});

const durationOptions = computed(() => {
  if (!room.data.value) {
    return [];
  }
  const res = [...durationMinutes.value, ...durationHours.value].filter(
    (item) => {
      return parseInt(item.value) <= room.data.value.max_duration_in_sec;
    },
  );
  return res;
});

const repeatOptions = [
  { value: "none", label: "Dont repeat", disabled: false },
  { value: "daily", label: "Daily", disabled: false },
  { value: "weekly", label: "Weekly", disabled: false },
];

const prevStep = () => {
  step.value = "INITIAL";
};

const initialDuration = computed(() => {
  const start = DateTime.fromISO(props.startDatetime, { zone: "utc" });
  const end = DateTime.fromISO(props.endDatetime, { zone: "utc" });
  const diff = end.diff(start, ["seconds"]).toObject();
  return `${diff.seconds}`;
});

const validateStartDay = (value: Date) => {
  const now = DateTime.now();
  const startDay = DateTime.fromJSDate(value);
  if (startDay.startOf("day").hasSame(now, "day") || startDay >= now) {
    return true;
  }
  return false;
};

const validateStartTime = (value: string) => {
  const now = DateTime.now().toLocal().toJSDate();
  const dateValue = DateTime.fromISO(value).toJSDate();
  if (!startDay.value?.value) {
    return true;
  }
  const startDate = DateTime.fromJSDate(startDay.value.value).toLocal();
  if (
    startDate
      .startOf("day")
      .hasSame(DateTime.now().toLocal().startOf("day"), "day")
  ) {
    if (dateValue > now) {
      return true;
    }
    return false;
  }
  return true;
};

const { validate: validateForm, meta: metaForm } = useForm({
  validationSchema: yup.object({
    name: yup
      .string()
      .required("Please enter a value")
      .min(3, "Please enter at least 3 characters.")
      .max(60, "Please enter no more than 60 characters.")
      .trim(),
    startDay: yup
      .date()
      .required("Please enter a value")
      .test(
        "startDay",
        "Meetings cannot be scheduled in the past. Please choose a future start day.",
        (value) => {
          return validateStartDay(value);
        },
      ),
    startTime: yup
      .string()
      .required("Please enter a value")
      .test(
        "startTime",
        "Meetings cannot be scheduled in the past. Please choose a future start time.",
        (value) => {
          return validateStartTime(value);
        },
      ),
    repeatability: yup.string().required("Please enter a value"),
    duration: yup.string().required("Please enter a value"),
  }),
});

const handleCreateMeeting = async () => {
  await validateForm();
  if (!metaForm.value.valid) {
    return;
  }
  const time =
    startTime.value && startTime.value.value
      ? DateTime.fromISO(startTime.value.value).toLocal().toUTC().toISOTime()
      : "";
  const day =
    startDay.value && startDay.value.value && startTime.value
      ? DateTime.fromJSDate(startDay.value.value)
          .toLocal()
          .plus({
            hours: DateTime.fromISO(startTime.value.value).toLocal().hour,
            minutes: DateTime.fromISO(startTime.value.value).toLocal().minute,
          })
          .toUTC()
          .toFormat("yyyy-MM-dd")
      : "";
  const payload = {
    name: nameInput.value?.value,
    repeatability: repeatability.value?.value,
    start_day: day,
    start_time: time,
    duration_in_sec: duration.value ? +duration.value.value : 3600,
  };
  await useAuthRequest<MeetingCreateResponse>(
    `${runtimeConfig.public.backendApiUrl}/meetings/${props.meetingId}`,
    {
      method: "PATCH",
      body: payload,
    },
  ).then(async (res) => {
    if (!res.pending.value && res.error.value) {
      step.value = "ERROR";
    }
    if (!res.pending.value && !res.error.value) {
      step.value = "SUCCESS";
      await dashboardStore.refreshCalendarEvents();
    }
  });
};
</script>
<style scoped lang="scss">
.ScheduleMeeting {
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: calc(100% - 16px);
    min-height: calc(100% - 16px);
  }
  &__close {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__step-indicator {
    @include label-l;
    font-variant-numeric: tabular-nums;
    position: absolute;
    padding: 8px 12px;
    color: $color-element-light-strong;
    background: $color-element-dark-disabled;
    border-radius: 12px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &__step-1 {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &__step-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__invite-header {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    margin-bottom: 16px;
    align-items: baseline;
  }
  &__invite-header-text {
    @include header-s;
    color: $color-element-dark-strong;
  }
  &__invite-header-description {
    @include body-l;
    color: $color-element-dark-medium;
  }
  &__illustration-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__illustration-heading {
    @include header-m;
    color: $color-element-dark-strong;
    margin-top: 4px;
    margin-bottom: 16px;
  }
  &__illustration-description {
    @include body-l;
    color: $color-element-dark-medium;
    text-align: center;
  }
  &__share-wrapper {
    margin-top: auto;
  }
  &__button-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-top: 1px solid $color-neutral-300;
  }
  &__button {
    @include button-primary;
    display: row;
    column-gap: 8px;
  }
  &__participants-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: 0;
    padding: 0;
    margin-top: 24px;
  }
  &__participants-list-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
